@import url(https://fonts.googleapis.com/css?family=Material+Icons|Lexend:300,600&display=swap);

body {
  background: #000;
  font-family: 'Lexend';
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div.center {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: 3px solid black;
  /* padding: 10px; */
  /* position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

#control {
  /* position: absolute;
  bottom: 100px; */
  width: 200px;
  margin: 200px auto 200px auto;
  height: 200px;
  /* right: 100px; */
  cursor: pointer;

}

#control .border {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #fff;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#control.is--playing .border {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none;
  border-bottom: none;
  animation: spin 1.5s ease-in-out infinite;
}

/* #control.is--playing .border {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border-top: none;
  border-bottom: none;
  animation: spin 1.5s ease-in-out infinite;
} */

.inicon {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 auto;
}

#control .play {
  margin: 0 auto;
  /* position: relative;
  top: 54px;
  left: 50px; */
  /* box-sizing: border-box; */
  height: 100px;
  width: 100px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  will-change: border-width;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #control.is--playing .play {
  border-style: double;
  border-width: 0px 0 0px 6px;
  transform: translate(-1px, 1px);
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.label {
  font-size: 1.3rem;
  color: #fff;
  text-align: center;

}

.top {
  margin-top: 50px;
}

.labellogin {
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  line-height: 20%;
  text-decoration: underline;
}

.labellogout {
  font-size: 1.2rem;
  color: rgb(186, 186, 186);
  text-align: center;
  line-height: 20%;
  text-decoration: underline;
}

.labelLogo {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  line-height: 20%;
}


.container--waitlistapi {
  margin: 0 auto;
  background-color: #e0e0e0;
}

.button--waitlistapi {
  background-color: #000000;
}

.statusTextContainer--waitlistapi {
  color: #000000;
}

.referralLinkField--text {
  color: #000000;
}

.featureList {
  font-size: 1.2rem;
  color: #fff;
  /* text-align: center; */
}

.labelfeature {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  text-decoration: underline;
}

.hcenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.underline {
  border-bottom: 1px solid #acecb9;
  width: 100%;
}

@media (max-width:480px) {
  .labellogin {
    font-size: 1.2rem;
    margin-top: 10;
  }
}

.bmcimage {
  height: 60px !important;
  width: 217px !important;
}