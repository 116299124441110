.modal {
    font-size: 12px;
    background-color: rgb(45, 45, 45);
    padding: 20px;
    paddingBottom: 0px
}

.modal>.header {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 5px;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    /* border: 1px solid #fefefe; */
}